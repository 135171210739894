import CustomFieldsActions from '@/components/customfields/CustomFieldsActions';

const cancelStop = {
	id: 'cancelStop',
	selectionType: 'single',
	label: 'grid.shippositioning.cancelStop.title',
	functionality: 'EDIT_SHIPPOSITIONING',
	checkAvailability: function (registries) {
		return registries.length > 0 && registries[0].statusid != 'AN' && registries[0].statusid != 'IN';
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-cancelStop-shippositioning-show', row);
	}
};

export default {
	gridactions: [cancelStop, CustomFieldsActions.configFields('shippositioning')],
	formactions: []
};
