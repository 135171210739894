<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('grid.shippositioning.cancelStop.title')"
			:modelName="modelName"
			:dialogName="cancelStop"
			:onOk="onOkCancelStop"
			:showDialogProp="showDialogCancelStop"
			:widthDialog="700"
		>
			<template slot="message">
				<v-container>{{ $t('grid.shippositioning.cancelStop.advise') }}</v-container>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'shippositioning-modals',
	data() {
		return {
			cancelStop: 'cancelStop',
			showDialogCancelStop: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkCancelStop(modalData) {
			return new Promise(async (resolve) => {
				await this.cancel(modalData);
				resolve(true);
			});
		},
		cancel(modalData) {
			this.$puiRequests.postRequest(
				'/shippositioning/cancel',
				null,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogRepair = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogRepair = false;
				},
				null,
				{ stopPk: modalData.id, visitPk: modalData.visitid }
			);
		}
	}
};
</script>
